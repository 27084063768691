const _temp0 = require("../images/amazon_icon.svg");

const _temp1 = require("../images/archive_icon.svg");

const _temp2 = require("../images/generic_link_icon.svg");

const _temp3 = require("../images/opengraph_banner.png");

const _temp4 = require("../images/typesense.svg");

module.exports = {
  "amazon_icon":   {
    "svg": _temp0
  },
  "archive_icon":   {
    "svg": _temp1
  },
  "generic_link_icon":   {
    "svg": _temp2
  },
  "opengraph_banner":   {
    "png": _temp3
  },
  "typesense":   {
    "svg": _temp4
  }
}